import EngagementAnalytics from "check_in/engagement_analytics";
import Analytics from "check_in/analytics";
import EventQueue from "check_in/EventQueue";

if (!window.analytics) {
  window.analytics = new Analytics();
}

export function initJsAnalytics() {
  window.eventQueue = new EventQueue();
  window.jsAnalytics = new EngagementAnalytics();
  window.jsAnalytics.page();
  window.eventQueue.unload();
}
