export default class EventQueue {
  static LOCAL_STORAGE_QUEUE_KEY = "eventQueue";

  constructor() {
    const savedQueue = this.#fetchQueueFromLocalStorage();
    if (savedQueue && $.isArray(savedQueue)) {
      this.queue = savedQueue;
    } else {
      this.queue = [];
    }
  }

  unload() {
    this.queue.forEach((event) => {
      window.analytics.track(event.name, event.properties);
    });
    this.queue = [];
    return this.#setQueueInLocalStorage();
  }

  enqueue(eventName, properties) {
    const event = { name: eventName, properties };
    this.queue.push(event);
    return this.#setQueueInLocalStorage();
  }

  #fetchQueueFromLocalStorage() {
    const { LOCAL_STORAGE_QUEUE_KEY } = EventQueue;
    const storedQueue = localStorage.getItem(LOCAL_STORAGE_QUEUE_KEY);
    return JSON.parse(storedQueue);
  }

  #setQueueInLocalStorage() {
    const { LOCAL_STORAGE_QUEUE_KEY } = EventQueue;
    const queueAsJson = JSON.stringify(this.queue);
    return localStorage.setItem(LOCAL_STORAGE_QUEUE_KEY, queueAsJson);
  }
}
