const base64ToHex = (str) => {
  const raw = atob(str);
  const hexData = [];
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    hexData.push(hex.length === 2 ? hex : `0${hex}`);
  }
  return hexData.join("");
};

export default base64ToHex;
