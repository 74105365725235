$(() => {
  $.rails.ajax = (options) => {
    if (!options.timeout) {
      let ajax_timeout_in_seconds;
      if (window.ENV !== undefined && window.ENV.ajax_timeout_in_seconds > 0) {
        ({ ajax_timeout_in_seconds } = window.ENV);
      } else {
        ajax_timeout_in_seconds = 10;
      }

      options.timeout = ajax_timeout_in_seconds * 1000;
    }

    $.ajax(options);
  };
});
