import initTranslationsHelper from "utils/initTranslationsHelper";

angular.module("check-in").directive("epiFlashError", () => {
  const translate = initTranslationsHelper("check_in.validate_form");

  return {
    restrict: "E",
    scope: { translationKey: "@" },
    template: `\
        <div class="flash">
          <div class="flash-error">{{flashErrorMessage}}</div>
        </div>\
      `,
    link: (scope) => {
      scope.flashErrorMessage = translate(scope.translationKey);
    },
  };
});
