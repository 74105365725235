const styles = {
  expandCollapseTriangle: {
    backgroundColor: "transparent",
    fontSize: 14,
    border: "none",
    cursor: "pointer",
    display: "inline",
    margin: "0 0 0 10px",
    marginRight: "10px",
    padding: "0",
    color: "white",
  },
  checkmarkHidden: {
    opacity: 0,
    transition: "visibility 0s 1s, opacity 0.5s linear",
  },
  copyButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    display: "inline",
    fontSize: "2em",
    margin: "0",
    padding: 0,
    textTransform: "none",
    color: "white",
  },
  trashCan: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    display: "inline",
    fontSize: "2em",
    margin: "0",
    padding: 0,
    textTransform: "none",
    color: "white",
  },
  hiddenTextArea: {
    height: 0,
    opacity: 0,
    position: "absolute",
    width: 0,
  },
  log: {
    backgroundColor: "#e4ddffdd",
    border: "1px solid #0003",
    borderRadius: "0",
    fontSize: "10px",
    overflowX: "scroll",
    width: "400px",
  },
  loggerForm: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1500,
    transform: "translateZ(2px)",
  },
  message: {
    padding: 6,
    wordWrap: "break-word",
    textIndent: -15,
    marginLeft: 15,
  },
  messageDivider: {
    margin: 0,
  },
  toolbar: {
    backgroundColor: "#0000dd",
    display: "flex",
    fontSize: "10px",
    justifyContent: "flex-end",
    padding: "6px",
  },
};

export default styles;
