import isEmpty from "lodash/isEmpty";

angular.module("check-in").directive("painDiagramModal", () => ({
  restrict: "A",
  link: (scope, elem) => {
    elem.find("input").addClass("pain-diagram-output");
    const painDiagram = elem.find(".pain-diagram");
    const signaturePadLineTop = -999;
    const signaturePadBlueColor = "#3b73b9";

    const closeModal = () => elem.magnificPopup("close");

    const getPlugin = () => painDiagram.data("plugin-signaturePad");

    scope.saveDiagram = () => {
      closeModal();
      const plugin = getPlugin();
      const drawingInstructions = JSON.parse(plugin.getSignatureString());

      if (!isEmpty(drawingInstructions)) {
        scope.painDiagramDrawn = true;
        scope.painDiagramPreview = plugin.getSignatureImage();
      } else {
        scope.painDiagramDrawn = false;
        scope.painDiagramPreview = null;
      }
    };

    scope.clearDiagram = () => {
      getPlugin().clearCanvas();

      scope.painDiagramDrawn = false;
      scope.painDiagramPreview = null;
      scope.$apply();
    };

    painDiagram
      .signaturePad({
        bgColour: "transparent",
        drawOnly: true,
        lineColour: signaturePadBlueColor,
        lineTop: signaturePadLineTop,
        output: ".pain-diagram-output",
        penColour: signaturePadBlueColor,
        penWidth: "5",
        validateFields: false,
      })
      .regenerate(scope.painDiagramData);

    scope.painDiagramPreview = getPlugin().getSignatureImage();
  },
}));
