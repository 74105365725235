angular.module("check-in").config(() => {
  const setSwipeTrackSettings = () => {
    const settingsObject = {
      hardware: {
        iOS: {
          imageCaptureQuality: "low",
          deviceSleepDisabled: false,
        },
      },
    };
    const settings = JSON.stringify(settingsObject);
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = `stBrowser://setAppSettings?settings=${settings}`;
    document.body.appendChild(iframe);
  };

  setSwipeTrackSettings();
});
