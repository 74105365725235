/* eslint-disable */
import merge from "lodash/merge";

export default class EngagementAnalytics {
  static get localStorageIpadIdentifierKey() {
    return "ipadIdentifier";
  }

  constructor(
    engagementAnalyticsId,
    eventQueueEnabled,
    analytics,
    eventQueue,
    engagementProperties,
  ) {
    if (!engagementAnalyticsId) { ({ engagementAnalyticsId } = window); }
    if (!eventQueueEnabled) { ({ eventQueueEnabled } = window); }
    if (!analytics) { ({ analytics } = window); }
    if (!eventQueue) { ({ eventQueue } = window); }
    if (!engagementProperties) { ({ engagementProperties } = window); }
    this.engagementAnalyticsId = engagementAnalyticsId;
    this.eventQueueEnabled = eventQueueEnabled;
    this.analytics = analytics;
    this.eventQueue = eventQueue;
    this.engagementProperties = merge(
      engagementProperties,
      { ipad_identifier: this._getIpadIdentifier() },
    );
  }

  track(event, eventProperties) {
    console.info(
      `track event:${event}; properties: ${JSON.stringify(eventProperties)}`);
    const properties = {
      ...this.engagementProperties,
      ...eventProperties,
      time: this._getCurrentTimeInSeconds(),
    };

    if (this.eventQueueEnabled) {
      return this.eventQueue.enqueue(event, properties);
    } else {
      return this.analytics.track(event, properties);
    }
  }

  page() {
    return this.analytics.page(
      window.location.pathname,
      {
        ...this.engagementProperties,
        time: this._getCurrentTimeInSeconds(),
      },
    );
  }

  _getIpadIdentifier() {
    let iPadIdentifier = localStorage.getItem(
      EngagementAnalytics.localStorageIpadIdentifierKey,
    );

    if (!iPadIdentifier) {
      iPadIdentifier = this._generateIpadIdentifier();
    }

    return iPadIdentifier;
  }

  _generateIpadIdentifier() {
    const iPadIdentifier = (Math.random() + 1).toString(36).substring(3);
    localStorage.setItem(
      EngagementAnalytics.localStorageIpadIdentifierKey,
      iPadIdentifier,
    );

    return iPadIdentifier;
  }

  _getCurrentTimeInSeconds() {
    const currentTimeInMs = Date.now();
    return Math.round(currentTimeInMs / 1000);
  }
}

/* eslint-enable */
