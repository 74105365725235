import { trackEvent, eventNames } from "utils/EventsTracking";

/* global Modal */
angular
  .module("check-in")
  .constant("BILLING_ADDRESS_ERROR", "wrong_billing_address")
  .constant("BILLING_ZIP_ERROR", "wrong_billing_zip")
  .controller(
    "PaymentContractsController",
    ($scope, $controller, $window, ClientValidator, BILLING_ADDRESS_ERROR, BILLING_ZIP_ERROR) => {
      $controller("SwipeCreditCardController", { $scope });

      const paymentData = () => {
        const { paymentContract, useAddress, useCreditCard } = $scope;

        return {
          billing_address: paymentContract[useAddress].billing_address,
          billing_zip: paymentContract[useAddress].billing_zip,
          credit_card_selection: useCreditCard,
          email: paymentContract.email,
        };
      };

      const isBillingAddressErrorPresent = () =>
        $scope.errors[$scope.useAddress].billing_address_error || $scope.errors[$scope.useAddress].billing_zip_error;

      const errorMessageData = (data) => ({
        errorMessage: data.error,
        fieldKey: data.error_type,
        translationKey: "field_error_payment_contracts",
      });

      $scope.$watch("agreeWithTerms", (agreeWithTerms) => {
        if (agreeWithTerms) {
          trackEvent(eventNames.PATIENT_AGREED_WITH_TERMS);
        }
      });

      $scope.isSameOption = (option) => option === $window.ENV.paymentsSame;

      $scope.isAnotherOption = (option) => option === $window.ENV.paymentsAnother;

      $scope.readEncryptedCardData = (encryptedData, ipadId) => {
        const paymentContract = paymentData();
        paymentContract.encrypted_data = encryptedData;
        $scope.onCreditCardSwipe(
          $window.ENV.createPaymentContractPath,
          { payment_contract: paymentContract },
          encryptedData.device_serial_number,
          ipadId,
        );
      };

      $scope.updateValidationMessageOnFailure = (data) => {
        $scope.errors = {};
        $scope.errors[$scope.useAddress] = {
          billing_address_error: data.error_type === BILLING_ADDRESS_ERROR,
          billing_zip_error: data.error_type === BILLING_ZIP_ERROR,
        };

        if (isBillingAddressErrorPresent()) {
          setTimeout(() => {
            ClientValidator.updateValidationErrors($("#payment_contract_form"), errorMessageData(data));
            Modal.closeActiveModal();
          }, 0);
        }
      };

      $scope.onCreatePaymentContractButtonClick = () => {
        if ($scope.isAnotherOption($scope.useAddress)) {
          trackEvent(eventNames.USED_DIFFERENT_ADDRESS);
        }

        if ($scope.isAnotherOption($scope.useCreditCard) && $scope.doesPaymentExist) {
          trackEvent(eventNames.USED_DIFFERENT_CARD);
        }

        if ($scope.isSameOption($scope.useCreditCard) && $scope.doesPaymentExist) {
          $scope.paymentStatus = $scope.PAYMENT_STATUS.SENDING;
          $scope.sendCreditCardData($window.ENV.createPaymentContractPath, { payment_contract: paymentData() });
        } else {
          $scope.initiateSwipeWorkflow();
        }
      };
    },
  );
