const BRAND_REGEXES = [
  { brand: "visa", regex: /^4[0-9]{3,}/ },
  { brand: "amex", regex: /^3[47][0-9]{1,}/ },
  { brand: "discover", regex: /^6(?:011|5[0-9]{2,})/ },
  { brand: "mastercard", regex: /^5[1-5][0-9]{2,}|222[1-9]|22[3-9][0-9]{1,}|2[3-6][0-9]{2,}|27[01][0-9]{1,}|2720/ },
];

const getCardBrand = (cardFirstDigits) => {
  const brandRegex = BRAND_REGEXES.find(({ regex }) => regex.test(cardFirstDigits));

  return brandRegex?.brand || "";
};

export default getCardBrand;
