import initTranslationsHelper from "utils/initTranslationsHelper";
import isEmpty from "lodash/isEmpty";

angular.module("check-in").directive("epiValidationError", () => {
  const translate = initTranslationsHelper("check_in.validate_form");

  return {
    restrict: "A",
    require: ["^form", "ngModel"],
    scope: false,
    link: (scope, elem, attrs, ctrls) => {
      const formController = ctrls[0];
      const modelController = ctrls[1];

      const headerErrorDiv = (key) =>
        `\
            <div class="flash">
              <div class="flash-error">${translate(key)}</div>
            </div>\
          `;

      const errorSpan = (error) => `<span class="field-error">${error}<span>`;

      const errorMessage = (controller, attributes) => {
        const errorName = Object.keys(controller.$error)[0];

        return attributes[`${errorName}ValidationMessage`] || errorName;
      };

      const refreshHeaderErrorMessage = (controller, element) => {
        const form = $(element).parents("form:first");
        form.children(".flash").remove();
        if (!controller.$valid) {
          form.prepend(headerErrorDiv("form_error"));
        }
      };

      scope.$on("clientValidation:trigger", () => {
        refreshHeaderErrorMessage(formController, elem);

        const fieldUnit = $(elem).parents(".field-unit").last();
        fieldUnit.children(".field-error").remove();

        if (isEmpty(modelController.$error)) {
          fieldUnit.removeClass("field-with-errors");
        } else {
          fieldUnit.addClass("field-with-errors");
          fieldUnit
            .children("label")
            .first()
            .before(errorSpan(errorMessage(modelController, attrs)));
        }
      });
    },
  };
});
