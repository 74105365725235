import "translations/check-in";
import "../stylesheets/application.scss";
import "polyfills";
import $ from "jquery";
import Log from "utils/logging";
import { initJsAnalytics } from "check_in/init-js-analytics";
import initClientScreenLogger from "components/LogToClientScreen/logger";

window.$ = $;
window.jQuery = $;

if (window.logToClientScreenEnabled) {
  initClientScreenLogger();
}

initJsAnalytics();

require.context("../images/check_in", true);
require("check_in/check-in-app");

Log.info("Javascript Loaded");
