import { trackEvent, eventNames } from "utils/EventsTracking";

/* global Modal */
angular.module("check-in").controller("PaymentsController", ($scope, $window, $controller) => {
  $controller("SwipeCreditCardController", { $scope });

  $scope.usePaymentAmount = $window.ENV.totalPaymentAmount;

  $scope.initialCardReaderCheck = (paymentEnabled) => {
    if (paymentEnabled) {
      $scope.tryToConnectToCardReader();
    }
  };

  $scope.readEncryptedCardData = (encryptedData, ipadId) => {
    const { copay, onCreditCardSwipe, paymentAmount } = $scope;
    const paymentSummary = {
      copay: copay.toFixed(2),
      other_amount: (paymentAmount - copay).toFixed(2),
      encrypted_data: encryptedData,
    };
    onCreditCardSwipe(
      $window.ENV.creditCardPaymentsPath,
      { payment_summary: paymentSummary },
      encryptedData.device_serial_number,
      ipadId,
    );
  };

  $scope.isNextCardButtonVisible = () => !$scope.paymentRequired || $scope.paymentAmount === 0;

  $scope.isPayWithCashButtonVisible = () => $scope.paymentMethod === $window.ENV.cashPaymentMethod;

  $scope.isPayWithCardButtonVisible = () =>
    $scope.paymentMethod === $window.ENV.creditCardPaymentMethod && $scope.paymentAmount !== 0;

  $scope.isOtherAmountRequired = () =>
    $scope.paymentMethod === $window.ENV.creditCardPaymentMethod &&
    $scope.usePaymentAmount === $window.ENV.otherPaymentAmount;

  $scope.onPayWithCashButtonClick = () => {
    trackEvent(eventNames.CASH_PAYMENT_ATTEMPT);
  };

  $scope.onPayWithCardButtonClick = () => {
    trackEvent(eventNames.CREDIT_CARD_PAYMENT_ATTEMPT);
    $scope.initiateSwipeWorkflow();
    $scope.$broadcast("clientValidation:trigger");
    Modal.toggle(".payment-button", $scope.creditCardForm.$invalid);
  };

  $scope.onOtherPaymentAmountInputClick = () => {
    $scope.usePaymentAmount = $window.ENV.otherPaymentAmount;
  };

  $scope.setPaymentAmount = () => {
    if ($scope.usePaymentAmount === $window.ENV.otherPaymentAmount) {
      $scope.paymentAmount = $scope.otherPaymentAmount.toFixed(2);
    } else {
      $scope.paymentAmount = $scope.total.toFixed(2);
    }
  };

  const setOtherPaymentAmount = () => {
    $scope.setPaymentAmount();

    if ($scope.usePaymentAmount !== $window.ENV.otherPaymentAmount) {
      $scope.otherPaymentAmount = undefined;
    }
  };

  $scope.$watchGroup(["usePaymentAmount"], setOtherPaymentAmount);
});
