import { trackEvent, eventNames } from "utils/EventsTracking";

angular
  .module("check-in")
  .constant("CONNECTION_RESET_URL", "stBrowser://resetDeviceConnection")
  .constant("CONNECTION_STATUS_CHECK_URL", "stBrowser://isDeviceConnected")
  .constant("FALLBACK_TIMEOUT", 3000)
  .constant("IFRAME_ID", "device_connection_iframe")
  .constant("MAX_CONNECTION_ATTEMPTS", 2)
  .constant("PAYMENT_SIMULATION", process.env.PAYMENT_SIMULATION || false)
  .constant("VERIFY_CONNECTION_TIMEOUT", 1000)
  .controller(
    "CardReaderStatusController",
    (
      $scope,
      $http,
      $timeout,
      CONNECTION_RESET_URL,
      CONNECTION_STATUS_CHECK_URL,
      FALLBACK_TIMEOUT,
      IFRAME_ID,
      MAX_CONNECTION_ATTEMPTS,
      PAYMENT_SIMULATION,
      VERIFY_CONNECTION_TIMEOUT,
    ) => {
      const removeExistingIframe = () => {
        const iframe = document.getElementById(IFRAME_ID);

        if (iframe) {
          iframe.remove();
        }
      };

      const createNewIframeWith = (url, onLoadCallback) => {
        removeExistingIframe();

        const iframe = document.createElement("iframe");
        iframe.id = IFRAME_ID;
        iframe.style.display = "none";
        iframe.src = url;
        if (onLoadCallback) {
          iframe.onload = () => onLoadCallback();
        }
        document.body.appendChild(iframe);
      };

      const createVerifyConnectionTask = () => {
        cancelVerifyConnectionTask();

        $scope.verifyConnectionTask = $timeout(() => $scope.verifyReaderConnection(), VERIFY_CONNECTION_TIMEOUT);
      };

      const cancelVerifyConnectionTask = () => {
        if ($scope.verifyConnectionTask) {
          $timeout.cancel($scope.verifyConnectionTask);
        }
      };

      const resetReaderConnection = () => {
        console.info("[card reader] reseting connection...");
        createNewIframeWith(CONNECTION_RESET_URL, $scope.verifyReaderConnection);
        createVerifyConnectionTask();
      };

      const totalAttemptsCount = () => $scope.previousConnectionAttempts + $scope.connectionAttempts;

      const trackConnectionSuccess = (ipadId) => {
        trackEvent(eventNames.CARD_READER_CONNECTION_SUCCESS, {
          attempts_count: totalAttemptsCount(),
          ipad_id: ipadId,
        });
      };

      const trackConnectionFailure = (ipadId) => {
        trackEvent(eventNames.CARD_READER_CONNECTION_FAILURE, {
          attempts_count: totalAttemptsCount(),
          ipad_id: ipadId,
        });
      };

      const createFallbackTask = () => {
        cancelFallbackTask();

        $scope.fallbackTask = $timeout(() => {
          $scope.connectionStatus = $scope.CONNECTION_STATUS.API_DOES_NOT_RESPOND;
        }, FALLBACK_TIMEOUT);
      };

      const cancelFallbackTask = () => {
        if ($scope.fallbackTask) {
          $timeout.cancel($scope.fallbackTask);
        }
      };

      const handleCardReaderConnectionStatus = (status, _ipadId) => {
        switch (status) {
          case $scope.CONNECTION_STATUS.CONNECTING:
            console.info("[card reader] trying to connect...");
            $scope.verifyReaderConnection();
            break;
          case $scope.CONNECTION_STATUS.SUCCESS:
            console.info("[card reader] connection successful");
            $scope.$broadcast("cardReaderConnected");
            break;
          case $scope.CONNECTION_STATUS.FAILURE:
            console.info("[card reader] connection failed");
            $scope.$broadcast("cardReaderConnectionFailed");
            break;
          case $scope.CONNECTION_STATUS.API_DOES_NOT_RESPOND:
            $scope.$broadcast("cardReaderConnected");
            break;
        }
      };

      $scope.CONNECTION_STATUS = {
        CONNECTING: 0,
        SUCCESS: 1,
        FAILURE: 2,
        API_DOES_NOT_RESPOND: 3,
      };

      $scope.connectingToCardReader = () => $scope.connectionStatus === $scope.CONNECTION_STATUS.CONNECTING;

      $scope.tryToConnectToCardReader = () => {
        createFallbackTask();

        if ($scope.connectionAttempts) {
          $scope.previousConnectionAttempts += $scope.connectionAttempts;
        }
        $scope.connectionAttempts = 0;
        $scope.connectionStatus = $scope.CONNECTION_STATUS.CONNECTING;
      };

      $scope.verifyReaderConnection = () => {
        cancelVerifyConnectionTask();

        if (PAYMENT_SIMULATION === "true") {
          console.info("[card reader] reader simulation initiated...");
          $scope.readerConnectionStatus(true);
        } else {
          console.info("[card reader] verifying reader connection...");
          $scope.connectionAttempts++;
          createNewIframeWith(CONNECTION_STATUS_CHECK_URL);
        }
      };

      $scope.readerConnectionStatus = (status, ipadId) => {
        cancelFallbackTask();

        if (status) {
          $scope.connectionStatus = $scope.CONNECTION_STATUS.SUCCESS;
          trackConnectionSuccess(ipadId);
        } else if ($scope.connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
          resetReaderConnection();
        } else {
          $scope.connectionStatus = $scope.CONNECTION_STATUS.FAILURE;
          trackConnectionFailure(ipadId);
        }
      };

      $scope.previousConnectionAttempts = 0;
      $scope.$watch("connectionStatus", handleCardReaderConnectionStatus);
    },
  );
