angular.module("check-in").factory("medicationFactory", ($http, HTTP_TIMEOUT) => ({
  addMedication: (medicationId) =>
    $http.post(
      "/medications",
      {
        new_medication_form: {
          medication_id: medicationId,
        },
      },
      { timeout: HTTP_TIMEOUT },
    ),
}));
