angular.module("check-in").controller("ReviewOfSystemsController", ($scope) => {
  $scope.initSelectedOptions = (name) => {
    const values = JSON.parse($(`[ng-model="${name}"]`).val());

    $(`[ng-model^="${name}_"]`).each((index, item) => {
      $scope[item.getAttribute("ng-model")] = values[index];
    });
  };

  $scope.changeSelectedOptions = (name, findingName) => {
    const selectedValues = $(`[ng-model^="${name}_"]`)
      .toArray()
      .map((item) => item.value);

    $(`[ng-model="${name}"]`).val(JSON.stringify(selectedValues));
    $scope.answers[findingName] = selectedValues.some((item) => !!item);
  };

  $scope.systemsOkChanged = (event) => {
    $scope.systemsOk = event.target.checked;
  };

  const setFormPristine = (answers) => {
    const answersValues = Object.values(answers || {});

    if (answersValues.includes(true)) {
      $scope.reviewOfSystemsForm.$setDirty();
    } else {
      $scope.reviewOfSystemsForm.$setPristine();
    }
  };

  $scope.$watch("answers", setFormPristine, true);
});
