angular.module("check-in").directive("patientSurveyModal", () => ({
  restrict: "A",
  link: (_scope, _elem, attrs) => {
    const openModal = () => {
      const elementId = `#${attrs.id}`;
      const properties = window.Modal.buildProperties({
        items: {
          src: elementId,
          type: "inline",
        },
      });
      $.magnificPopup.open(properties);
    };

    angular.element(document).ready(() => openModal());
  },
}));
