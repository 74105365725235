angular.module("check-in").directive("epiNewMedicationForm", () => ({
  restrict: "A",
  controller: ($scope, medicationFactory) => {
    let medicationForm = null;

    const resetMedicationForm = () => {
      $scope.serverError = false;
      medicationForm = { name: null, id: null };
    };

    const addMedicationSuccesCallback = () => {
      const { id, name } = medicationForm;
      $scope.medications.push({
        id: `medication_${id}`,
        name,
        frequency: null,
        currentlyTaking: "0",
      });
      resetMedicationForm();
    };

    resetMedicationForm();

    $scope.validMedication = () => medicationForm.id !== null;

    $scope.medicationSelected = (name, id) => {
      medicationForm = { ...medicationForm, name, id };
      $scope.$apply();
    };

    $scope.addMedication = () =>
      medicationFactory.addMedication(medicationForm.id).then(() => addMedicationSuccesCallback());

    $scope.medicationInvalidated = () => {
      resetMedicationForm();
      $scope.$apply();
    };
  },
  link: (scope, elem) => {
    let submissionAttempts = 0;

    const resetAddMedicationModal = () => {
      elem.find("#new_medication_form_medication_id").val("");
      elem[0].reset();
      window.Ladda.stopAll();
      $.magnificPopup.close();
    };

    const handleServerError = () => {
      scope.serverError = true;
      window.Ladda.stopAll();
    };

    const retrySubmission = () => {
      submissionAttempts++;
      elem.trigger("submit");
    };

    const handleError = () => (submissionAttempts > 0 ? handleServerError() : retrySubmission());

    elem.on("submit", (e) => {
      e.preventDefault();
      scope
        .addMedication()
        .then(() => resetAddMedicationModal())
        .catch(() => handleError());
    });
  },
}));
