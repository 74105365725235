import readIDTechCardData from "utils/readIDTechCardData";
import readMagTekCardData from "utils/readMagTekCardData";

window.stBrowserDidReadEncryptedCardData = (inputData, ipadId) => {
  const $scope = angular.element($(".primary-content[ng-controller]")).scope();
  $scope.$apply(() => $scope.readEncryptedCardData(readMagTekCardData(inputData), ipadId));
};

window.stBrowserDeviceStatus = (status, ipadId) => {
  const $scope = angular.element($("[ng-controller]")).scope();
  $scope.$apply(() => $scope.readerConnectionStatus(status, ipadId));
};

window.stBrowserDidReadUMCardData = (inputData, ipadId) => {
  const $scope = angular.element($(".primary-content[ng-controller]")).scope();
  $scope.$apply(() => $scope.readEncryptedCardData(readIDTechCardData(inputData), ipadId));
};
