import initTranslationsHelper from "utils/initTranslationsHelper";

angular.module("check-in").factory("ClientValidator", () => {
  const FORM_ERROR_MESSAGE_CLASS = "form-error-message";
  const INPUT_ERROR_MESSAGE_CLASS = "error-message";
  const INVALID_DATA_ERROR_MESSAGE = "invalid-data-error-message";
  const translate = initTranslationsHelper("check_in.validate_form");

  const getErrorMessageElement = (className, message) => `<div class="${className}">${message}</div>`;

  const updateValidationErrors = (form, data) => {
    const { errorMessage, translationKey, fieldKey } = data;
    $(".invalid-data").each(function () {
      const invalidComponent = $(this);
      const errorMessageKey = invalidComponent.attr(INVALID_DATA_ERROR_MESSAGE) || errorMessage;
      const field = translate(`${translationKey}.${fieldKey}`);
      const message = translate(errorMessageKey, { field });
      updateValidationMessage(invalidComponent, message);
      invalidComponent.find("input").addClass("invalid");
    });
    form.find(".form-error-message").text(translate("form_error"));
    form.addClass("validated invalid");
  };

  const updateValidationMessage = (invalidComponent, message) => {
    const errorMessageElement = invalidComponent.find(`.${INPUT_ERROR_MESSAGE_CLASS}`);
    if (errorMessageElement.length > 0) {
      errorMessageElement.text(message);
    } else {
      invalidComponent.append(getErrorMessageElement(INPUT_ERROR_MESSAGE_CLASS, message));
    }

    invalidComponent.addClass("invalid");
  };

  return {
    FORM_ERROR_MESSAGE_CLASS,
    INPUT_ERROR_MESSAGE_CLASS,
    getErrorMessageElement,
    translate,
    updateValidationErrors,
    updateValidationMessage,
  };
});
