import isEmpty from "lodash/isEmpty";
import reject from "lodash/reject";
import some from "lodash/some";
import size from "lodash/size";

angular.module("check-in").controller("PainAssessmentController", ($scope, $window) => {
  const patientHasPegPain = () => $scope.nonZeroAnswerSelected() && $scope.allQuestionsAnswered();

  const patientDoesNotHavePegPain = () =>
    $scope.painAssessmentPeg() && $scope.allQuestionsAnswered() && !$scope.nonZeroAnswerSelected();

  $scope.nonZeroAnswerSelected = () => some($scope.pegAnswers, (value) => parseInt(value, 10) > 0);

  $scope.allQuestionsAnswered = () => {
    const answers = reject($scope.pegAnswers, (value) => isEmpty(value));
    return size(answers) === 6;
  };

  $scope.painAssessmentPeg = () => $scope.painAssessmentType === $window.ENV.fullBodyPeg;

  $scope.showAddDiagram = () => {
    if ($scope.painAssessmentPeg()) {
      return patientHasPegPain() && !$scope.painDiagramDrawn;
    }
    return !$scope.painDiagramDrawn;
  };

  $scope.showPainSubmission = () => $scope.painDiagramDrawn || patientDoesNotHavePegPain();
});
