angular
  .module("check-in")
  .constant("DEFAULT_PRECISION", 2)
  .directive("decimalPrecision", (DEFAULT_PRECISION) => {
    const numberOfDecimalPlaces = (viewValue) => {
      const index = viewValue.indexOf(".");

      return index >= 0 ? viewValue.length - index - 1 : 0;
    };

    return {
      restrict: "A",
      require: "ngModel",
      link: (scope, element, attrs, ngModel) => {
        const decimalPrecision = parseInt(attrs.decimalPrecision, 10) || DEFAULT_PRECISION;

        const formatNumber = (viewValue) => {
          const decimalPlaces = numberOfDecimalPlaces(viewValue);
          const formattedValue =
            decimalPlaces < decimalPrecision
              ? parseFloat(viewValue).toFixed(decimalPrecision)
              : viewValue.slice(0, decimalPrecision - decimalPlaces);

          if (formattedValue !== viewValue) {
            ngModel.$setViewValue(formattedValue);
            ngModel.$render();
          }
          return formattedValue;
        };

        element.on("blur", () => {
          const inputValue = element.val();

          if (inputValue && numberOfDecimalPlaces(inputValue) < decimalPrecision) {
            formatNumber(inputValue);
          }
        });

        ngModel.$parsers.push((inputValue) => {
          if (!inputValue || numberOfDecimalPlaces(ngModel.$viewValue) <= decimalPrecision) {
            return inputValue;
          }
          parseFloat(formatNumber(ngModel.$viewValue));
          return undefined;
        });
      },
    };
  });
