import filter from "lodash/filter";
import first from "lodash/first";

angular.module("epi-autocomplete").directive("epiAutocomplete", () => ({
  restrict: "A",
  transclude: true,
  template: `\
      <div ng-transclude></div>
      <div class="autocomplete-field">
        <label for="{{inputId}}">{{label}}</label>
        <input id="{{inputId}}" type="text" value="{{selectedValue}}">
      </div>\
    `,
  scope: {
    alwaysAppend: "=",
    autocompleteOptions: "=epiAutocomplete",
    maxHeight: "@",
    noResultsNotice: "@",
    onInvalidateCallback: "&",
    onSelectCallback: "&",
    serviceUrl: "@",
  },
  link: (scope, elem) => {
    const AJAX_DELAY = 300;
    const NON_AJAX_DELAY = 0;

    const originalInput = elem.find("[ng-transclude] input");
    const originalLabel = elem.find("[ng-transclude] label");

    scope.label = originalLabel.text();
    scope.inputId = `${originalInput.attr("id")}_autocomplete`;

    const calculateDeferRequest = () => (scope.serviceUrl ? AJAX_DELAY : NON_AJAX_DELAY);

    const findPreviouslySelectedOption = () => first(filter(scope.autocompleteOptions, { data: originalInput.val() }));

    const bootstrapAutocomplete = () => {
      const selectedOption = findPreviouslySelectedOption();
      if (selectedOption) {
        scope.selectedValue = selectedOption.value;
      }
    };

    const hideOriginalFields = () => {
      originalLabel.hide();
      originalInput.attr("type", "hidden");
    };

    const associateValidationErrors = () => {
      elem.find(".autocomplete-field").append(elem.find("[ng-transclude] .field-error"));
    };

    bootstrapAutocomplete();
    hideOriginalFields();
    associateValidationErrors();

    scope.deferRequestBy = calculateDeferRequest();

    elem.find(".autocomplete-field input").autocomplete({
      alwaysAppend: scope.alwaysAppend,
      autoSelectFirst: true,
      deferRequestBy: scope.deferRequestBy,
      lookup: scope.autocompleteOptions,
      maxHeight: scope.maxHeight || 250,
      minChars: 2,
      noSuggestionNotice: scope.noResultsNotice,
      serviceUrl: scope.serviceUrl,
      showNoSuggestionNotice: true,
      onInvalidateSelection: () => {
        scope.onInvalidateCallback();
      },
      onSelect: (suggestion) => {
        const { data, value } = suggestion;
        originalInput.val(data);

        scope.onSelectCallback({ name: value, id: data });
      },
    });
  },
}));
