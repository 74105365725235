angular
  .module("check-in")
  .constant("YES_VALUE", "Y")
  .controller("UnifiedInformationController", ($scope, YES_VALUE) => {
    $scope.familySizeProvided = () => !!parseInt($scope.familySize, 10);

    $scope.isAgriculturalWorker = () => $scope.agriculturalWorker === YES_VALUE;

    $scope.isHomeless = () => $scope.homeless === YES_VALUE;
  });
