angular.module("check-in").factory("allergyFactory", () => ({
  addAllergy: (allergyId, symptomId, severityId) => {
    const allergyReviewForm = document.querySelector("#new_allergies_review");

    const idInput = document.createElement("input");
    idInput.setAttribute("type", "hidden");
    idInput.setAttribute("name", "allergies_review[allergy_ids][]");
    idInput.setAttribute("value", allergyId);

    const reactionsInput = document.createElement("input");
    reactionsInput.setAttribute("type", "hidden");
    reactionsInput.setAttribute("name", `allergies_review[allergy_symptoms][${allergyId}]`);
    reactionsInput.setAttribute("value", symptomId);

    const severitiesInput = document.createElement("input");
    severitiesInput.setAttribute("type", "hidden");
    severitiesInput.setAttribute("name", `allergies_review[allergy_severities][${allergyId}]`);
    severitiesInput.setAttribute("value", severityId);

    allergyReviewForm.appendChild(idInput);
    allergyReviewForm.appendChild(reactionsInput);
    allergyReviewForm.appendChild(severitiesInput);
  },
}));
