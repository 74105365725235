angular.module("select-default").directive("selectWithDefault", () => ({
  restrict: "A",
  link: (_scope, elem, attrs) => {
    const { defaultValue } = attrs;

    if (defaultValue !== undefined) {
      let alreadyFocused = false;

      elem.on("focus", () => {
        if (alreadyFocused || elem.val() !== "") {
          alreadyFocused = false;
          return true;
        }
        alreadyFocused = true;
        elem.val(defaultValue);
        elem.trigger("blur");
        elem.trigger("focus");
        return undefined;
      });
    }
  },
}));
