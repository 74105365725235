angular.module("check-in").controller("SurgeriesController", ($scope) => {
  $scope.init = (surgeriesCount, noSurgeriesQuestionEnabled) => {
    $scope.surgeriesCount = surgeriesCount;
    $scope.noSurgeriesQuestionEnabled = noSurgeriesQuestionEnabled;
  };

  $scope.$watch("surgeriesCount", (surgeriesCount) => {
    const showQuestion = $scope.noSurgeriesQuestionEnabled && surgeriesCount === 0;
    const showButtons = !showQuestion;

    $scope.showNextButton = showButtons;
    $scope.showAddSurgeriesButton = showButtons;
    $scope.showHadSurgeriesQuestion = showQuestion;
  });

  $scope.$watch("hadSurgeries", (hadSurgeries) => {
    if (hadSurgeries === "true") {
      $scope.showNextButton = false;
      $scope.showAddSurgeriesButton = true;
    } else if (hadSurgeries === "false") {
      $scope.showNextButton = true;
      $scope.showAddSurgeriesButton = false;
    }
  });
});
