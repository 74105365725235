import "jquery-ujs";

// Include local JS classes
import { initModals } from "check_in/modals";
import { initCheckInForm } from "check_in/check_in_form";
import { initCheckInTimeout } from "check_in/check_in_timeout";
import { initStructuredHpi } from "check_in/structured_hpi";
import * as Ladda from "ladda";

window.Ladda = Ladda;

require("jquery-autocomplete");

require("magnific-popup");
require("jquery.signaturepad");
require("json2.min");
require("cocoon");

require("angular");
require("angular-base64-upload");
require("angular-credit-cards");
require("angular-keep-values");
require("angular-sanitize");

initModals();
initCheckInForm();
initCheckInTimeout();

require("check_in/swipe_track_camera");
require("check_in/swipe_track_card_reader");
require("check_in/swipe_track_scanner");

// Include local Angular elements
require("check_in/check-in");
require("check_in/epi-autocomplete");
require("check_in/select-default");
require("check_in/track-event");

require("check_in/configs/http_provider_config");
require("check_in/configs/rails_remote");
require("check_in/configs/swipe_track_config");

require("check_in/factories/allergyFactory");
require("check_in/factories/clientValidator");
require("check_in/factories/medicationFactory");

require("check_in/filters/highlight");
require("check_in/filters/translate");

require("check_in/directives/decimalPrecision");
require("check_in/directives/epiAutocomplete");
require("check_in/directives/epiFlashError");
require("check_in/directives/epiNestedFields");
require("check_in/directives/epiNewAllergyForm");
require("check_in/directives/epiNewMedicationForm");
require("check_in/directives/epiTriggerCamera");
require("check_in/directives/epiTriggerQrCodeScanner");
require("check_in/directives/epiValidationError");
require("check_in/directives/onFinishRender");
require("check_in/directives/painDiagramModal");
require("check_in/directives/patientSurveyModal");
require("check_in/directives/remoteCheckInForm");
require("check_in/directives/selectWithDefault");
require("check_in/directives/signatureModal");
require("check_in/directives/trackEvent");
require("check_in/directives/validateEmail");
require("check_in/directives/validateForm");
require("check_in/directives/removeNgEmailValidation");

require("check_in/controllers/ConsentController");
require("check_in/controllers/CrafftController");
require("check_in/controllers/CardReaderStatusController");
require("check_in/controllers/CurrentMedicationsController");
require("check_in/controllers/CustomConsentController");
require("check_in/controllers/MedicalHistoryController");
require("check_in/controllers/PainAssessmentController");
require("check_in/controllers/PaymentContractsController");
require("check_in/controllers/PaymentsController");
require("check_in/controllers/PersonalInformationController");
require("check_in/controllers/ReviewOfSystemsController");
require("check_in/controllers/SurgeriesController");
require("check_in/controllers/SwipeCreditCardController");
require("check_in/controllers/UnifiedInformationController");

document.addEventListener("DOMContentLoaded", initStructuredHpi);
