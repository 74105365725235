class Modal {
  static BODY_CLASS = "opened-modal-state";

  static DISABLED_MODAL_CLASS = "modal-disabled";

  static DEFAULT_PROPERTIES = {
    autoFocusLast: false,
    closeOnBgClick: false,
    fixedBgPos: true,
  };

  static PAGE_LOAD_MODAL_PROPERTIES = {
    type: "inline",
    showCloseBtn: false,
    enableEscapeKey: false,
  };

  static buildCallbacks() {
    let screenTopPosition = 0;

    return {
      beforeOpen: () => {
        screenTopPosition = window.scrollY;
        $("html, body").addClass(Modal.BODY_CLASS);
      },
      beforeClose: () => {
        $("html, body").removeClass(Modal.BODY_CLASS);
        window.scrollTo(0, screenTopPosition);
      },
    };
  }

  static buildProperties(properties) {
    Object.keys(Modal.DEFAULT_PROPERTIES).forEach((key) => {
      properties[key] = Modal.DEFAULT_PROPERTIES[key];
    });
    properties.callbacks = Modal.buildCallbacks();

    return properties;
  }

  static initilizePopup(obj, properties) {
    properties.disableOn = () => !obj.hasClass(`${Modal.DISABLED_MODAL_CLASS}`);
    return obj.magnificPopup(properties);
  }

  static toggle(selector, disabled) {
    $(selector).toggleClass(Modal.DISABLED_MODAL_CLASS, disabled);
  }

  static onOpen(selector, handler) {
    return $(selector).on("mfpOpen", handler);
  }

  static onClose(selector, handler) {
    return $(selector).on("mfpClose", handler);
  }

  static openPageLoadModal() {
    const modalOnPageLoad = $("[data-page-load-modal]");

    if (modalOnPageLoad.length > 0) {
      const properties = Modal.buildProperties(Modal.PAGE_LOAD_MODAL_PROPERTIES);
      properties.items = { src: `#${modalOnPageLoad.get(0).id}` };
      $.magnificPopup.open(properties, 0);
    }
  }

  static closeActiveModal() {
    $.magnificPopup.instance.close();
  }
}

export function initModals() {
  window.Modal = Modal;

  $(() => {
    const remoteModalProperties = Modal.buildProperties({ type: "ajax" });
    $("[data-remote-modal]").each((_, obj) => {
      Modal.initilizePopup($(obj), remoteModalProperties);
    });

    const inlineModalProperties = Modal.buildProperties({ type: "inline" });
    $("[data-inline-modal]").each((_, obj) => {
      Modal.initilizePopup($(obj), inlineModalProperties);
    });

    $("body").on("click", ".close-modal-button", () => $.magnificPopup.close());

    Modal.openPageLoadModal();
  });
}
