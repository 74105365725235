import getEnv from "utils/getEnv";

const regexes = getEnv("regexes");

angular.module("check-in").directive("validateEmail", () => ({
  require: "ngModel",
  restrict: "",
  link: (scope, elm, attrs, ctrl) => {
    if (ctrl && ctrl.$validators.email && regexes.email_regex) {
      ctrl.$validators.email = (modelValue) => ctrl.$isEmpty(modelValue) || regexes.email_regex.test(modelValue);
    }
  },
}));
