import { trackEvent, eventNames } from "utils/EventsTracking";

angular
  .module("check-in")
  .constant("FRONT_CAMERA_FLAG", "?camera=front")
  .constant("SWIPE_TRACK_CAMERA_URL", "stBrowser://capturePicture")
  .directive("epiTriggerCamera", ($window, FRONT_CAMERA_FLAG, SWIPE_TRACK_CAMERA_URL) => ({
    restrict: "A",
    link: (_scope, elem) => {
      const openCamera = (e) => {
        e.preventDefault();
        trackEvent(eventNames.OPEN_CAMERA);
        let cameraUrl = SWIPE_TRACK_CAMERA_URL;
        if ($window.checkingInAsPatient) {
          cameraUrl += FRONT_CAMERA_FLAG;
        }

        $window.location.href = cameraUrl;
      };

      elem.on("click", openCamera);
    },
  }));
