angular.module("check-in").directive("remoteCheckInForm", ($compile, $window) => ({
  transclude: true,
  template: `\
        <epi-flash-error
          ng-show="serverError"
          translation-key="ajax_server_error"
        ></epi-flash-error>
        <div ng-transclude></div>\
      `,
  restrict: "A",
  link: (scope, elem) => {
    const UNPROCESSABLE_ENTITY = 422;
    let requestAttempts = 0;

    const resetRequestAttempts = () => {
      requestAttempts = 0;
    };

    const remoteRedirect = (xhr) => {
      $window.location.href = xhr.getResponseHeader("location");
    };

    const displayValidationErrors = (responseText) => {
      const newForm = angular.element(responseText);
      $compile(newForm)(scope);
      elem.replaceWith(newForm);
    };

    const retryRequest = () => {
      requestAttempts += 1;
      elem.submit();
    };

    const handleServerError = () => {
      resetRequestAttempts();
      scope.serverError = true;
      scope.$apply();
      window.Ladda.stopAll();
    };

    elem.on("ajax:success", (_e, _response, _status, xhr) => {
      remoteRedirect(xhr);
      resetRequestAttempts();
    });

    elem.on("ajax:error", (_, response) => {
      if (response.status === UNPROCESSABLE_ENTITY) {
        displayValidationErrors(response.responseText);
      } else if (requestAttempts > 0) {
        handleServerError();
      } else {
        retryRequest();
      }
    });
  },
}));
